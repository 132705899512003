<template>
  <div class="" v-loading="loading" style="overflow-x: hidden">
    <el-row class="grid-top" v-if="!hasFinishOpenStep">
      <el-col :span="3"><div class="fill">&emsp;</div></el-col>
      <el-col :span="18" class="top">
        <div class="">
          <h4>开店任务</h4>
          <p>完成开张任务后可以开始正式售卖商品，使用提现等全部功能。</p>
        </div>
        <div class="btn">
          <router-link to="/applyOpenStore">
            <button type="button" class="">立即开张</button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="3"><div class="fill">&emsp;</div></el-col>
    </el-row>
    <el-row type="flex" v-if="certificationStatus"  class="row-bg margin-top-30" justify="center" :gutter="50">
      <el-col :span="9">
        <div class="grid-content">
          <h2>待处理订单</h2>
          <el-row type="flex" class="" justify="space-around">
            <el-col :span="6">
              <div class="cursor-pointer"  @click="jumpRouter('/supplierOrderList?status=1')">
                <div class="center f24">待支付<i class="el-icon-arrow-right"></i></div>
                <div class="center f30 mt10">{{ waitPayOrderCount }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="cursor-pointer"  @click="jumpRouter('/supplierOrderWaitSendList')">
                <div class="center f24">待发货<i class="el-icon-arrow-right"></i></div>
                <div class="center f30 mt10">{{ waitSendOrderCount }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="cursor-pointer"  @click="jumpRouter('/supplierRefundOrderList?recordType=1')">
                <div class="center f24">售后中<i class="el-icon-arrow-right"></i></div>
                <div class="center f30 mt10">{{ waitAfterSaleOrderCount }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="grid-content">
          <h2>今日数据</h2>
          <el-row type="flex" class="" justify="space-around">
            <el-col :span="10">
              <div class="center f24">
                支付金额
              </div>
              <div class="center f30 mt10 cred">¥ {{ todayPayAmount }}</div>
              <div class="center f16 mt10">昨日 ¥{{ yesterdayPayAmount }}</div>
            </el-col>
            <el-col :span="6">
              <div class="center f24">订单数</div>
              <div class="center f30 mt10">
                {{ todayOrderCount }}
              </div>
              <div class="center f16 mt10">昨日 {{ yesterdayorderCount }}</div>
            </el-col>
            <el-col :span="6">
              <div class="center f24">访客数</div>
              <div class="center f30 mt10">
                {{ todayVisitorCount }}
              </div>
              <div class="center f16 mt10">
                昨日 {{ yesterdayVisitorCount }}
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" v-if="certificationStatus"  class="row-bg" justify="center" :gutter="50">
      <el-col :span="9">
        <div class="grid-content">
          <h2>商品管理</h2>
          <el-row type="flex" class="" justify="space-around">
            <el-col :span="6">
              <div class="cursor-pointer"  @click="jumpRouter('/supplierGoods?status=1')">
                <div class="center f24">在售中<i class="el-icon-arrow-right"></i></div>
                <div class="center f30 mt10">{{ onSaleGoodsCount }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="cursor-pointer"  @click="jumpRouter('/supplierGoodsReview?status=2')">
                <div class="center f24">待审核<i class="el-icon-arrow-right"></i></div>
                <div class="center f30 mt10">{{ waitCheckGoodsCount }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="cursor-pointer"  @click="jumpRouter('/supplierEditGoods')">
                <div class="center f24">新增商品</div>
                <div class="center f30 mt10 cred"><i class="el-icon-circle-plus-outline"></i></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="grid-content">
          <h2>限时活动</h2>
          <el-row type="flex" class="" justify="space-around">
            <el-col :span="6">
              <div class="center f24">限时活动~敬请期待</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { URL} from "../../config";
export default {
  name: "index",
  components: {},
  props: {
    certificationStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,

      hasFinishOpenStep: true /* 是否完成开店步骤 */,

      onSaleGoodsCount: "" /* 在售商品数 */,

      todayOrderCount: "" /*今天订单数 */,

      todayPayAmount: "" /* 今天支付金额 */,

      todayVisitorCount: "" /*今天访问数 */,

      waitAfterSaleOrderCount: "" /* 待售后 */,

      waitCheckGoodsCount: "" /* 待审核商品数 */,

      waitPayOrderCount: "" /* 待支付单数 */,

      waitSendOrderCount: "" /* 待发货单数 */,

      yesterdayPayAmount: "" /* 昨天支付金额 */,

      yesterdayorderCount: "" /* 昨天订单数  */,

      yesterdayVisitorCount: "" /* 昨天访问数*/,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 查询列表数据
     */
    getList() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.axios
          .get(URL.supplierBasicMessage.getIndexData, {})
          .then((res) => {
            if (res.code !== 0) {
              this.$message.error(res.msg);
            } else {
              if(res.data.hasFinishOpenStep){
                this.$message({
                  message: '欢迎回来~',
                  type: 'success'
                });
              }else{
                this.$message({
                  message: '快去完成开店任务吧~',
                  type: 'success'
                });
              }
              this.hasFinishOpenStep = res.data.hasFinishOpenStep;

              this.onSaleGoodsCount = res.data.onSaleGoodsCount;
              /*todayData start*/
              this.todayOrderCount = res.data.todayData.orderCount;
              this.todayPayAmount = res.data.todayData.payAmount;
              this.todayVisitorCount = res.data.todayData.visitorCount;
              /*todayData end*/
              this.waitAfterSaleOrderCount = res.data.waitAfterSaleOrderCount;
              this.waitCheckGoodsCount = res.data.waitCheckGoodsCount;
              this.waitPayOrderCount = res.data.waitPayOrderCount;
              this.waitSendOrderCount = res.data.waitSendOrderCount;

              /*yesterdayData start*/
              this.yesterdayPayAmount = res.data.yesterdayData.payAmount;
              this.yesterdayorderCount = res.data.yesterdayData.orderCount;
              this.yesterdayVisitorCount = res.data.yesterdayData.visitorCount;
              /*yesterdayData end*/
            }
            resolve(res);
          })
          .catch((res) => {
            this.tableData = [];
            // reject(res);
          })
          .finally((res) => {
            this.loading = false;
          });
      });
    },
    jumpRouter(to){
      this.$router.push({path:to});
    },
  },
};
</script>

<style lang="less" scoped>
.el-col {
  border-radius: 10px;
      min-width: 100px;
}
.row-bg {
  margin-bottom: 24px;
}
.grid-top {
  margin: 24px 0;
  min-height: 105px;
}
.grid-content {
  min-height: 300px;
  background: #fff;
  color: #353535;
  padding: 32px;
  border-radius: 8px;
}
.grid-content h2 {
  margin-bottom: 50px;
  font-size: 1.8rem;
}
.center {
  text-align: center;
}
.f24 {
  font-size: 24px;
}
.mt10 {
  margin-top: 10px;
}
.f16 {
  font-size: 16px;
}
.cred {
  color: red;
}
.f30 {
  font-size: 30px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 24px 32px;
  margin: 24px 0;
  border-radius: 8px;
}
.top h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
}
.top p {
  margin: 0;
  font-size: 16px;
  opacity: 0.5;
}
.btn button {
  min-width: 116px;
  height: 40px;
  padding: 0 22px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background-color: #ff6146;
  border: 0;
  color: #fff;
}
.fill {
  min-height: 105px;
}
.el-row--flex {
  margin-left: 0;
  margin-right: 0;
}
</style>
